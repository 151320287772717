import React from "react";
import { ReactElement } from "react";
import {
  Chain,
  useAccount,
  useBalance,
  useConnect,
  useDisconnect,
  useNetwork,
  useProvider,
  useSigner,
} from "wagmi";
import type { Provider, Signer } from "@wagmi/core";
import { formatEther } from "ethers/lib/utils";

export type Web3ContextType = {
  connectorIds: string[],
  connectToId: (id: string) => void,
  metadataForId: (id: string) => { nameWallet: string; iconWallet: string | null };
  disconnect: () => void;
  isConnected: boolean;
  isConnecting: boolean;
  provider: Provider;
  signer?: Signer;
  chain?: Chain;
  account?: `0x${string}`;
  mainAssetBalance: string;
};

const Web3Context = React.createContext(null as Web3ContextType | null);

export const Web3Provider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { connect: connectWallet, connectors } = useConnect();

  const { chain } = useNetwork();
  const { disconnect } = useDisconnect();
  const {
    isConnected,
    isConnecting,
    address: account,
    connector,
  } = useAccount();
  const provider = useProvider();
  const { data: signer } = useSigner();
  const { data: mainAssetBalance } = useBalance({
    address: account,
  });

  const baseConnectors = ['metaMask', 'walletConnect'];
  const bannedWallets = new Set(['walletConnectLegacy']);
  const connectorIds = [
    ...baseConnectors,
    ...connectors.map(conn => conn.id as string).filter(id => baseConnectors.find(baseId => baseId === id) == null)
  ].filter(id => !bannedWallets.has(id));
  const connectToId = (id) => {
    const connector = connectors.find(
      (connector) => connector.id === id,
    );
    connectWallet({
      connector,
    });
  }

  // TODO: there are better ways to do this
  const metadataForId = (id: string) => {
    const iconWallet = (() => {
      if (id === 'metaMask') {
        return "/media/logos/iconMetamask.svg";
      }
      if (id === 'walletConnect') {
        return "/media/logos/walletConnect.svg";
      }
      return null;
    })();
    const nameWallet = (() => {
      if (id === 'metaMask') {
        return "MetaMask";
      }
      if (id === 'walletConnect') {
        return "WalletConnect";
      }
      return id;
    })();

    return { iconWallet, nameWallet };
  }

  return (
    <Web3Context.Provider
      value={{
        isConnected,
        connectorIds,
        connectToId,
        metadataForId,
        isConnecting,
        account,
        chain,
        mainAssetBalance: formatEther(mainAssetBalance?.value ?? 0),
        disconnect,
        provider,
        // @ts-ignore
        signer,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export const useWeb3Provider = () => {
  const context = React.useContext(Web3Context);
  if (context === undefined) {
    throw new Error("useWeb3Provider must be used within a Web3Provider");
  }
  return context as Web3ContextType;
};
